/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { HakkaDefinitionCell } from "./InputSearch";

interface ResultProps {
  hakkaDefinitionCells: HakkaDefinitionCell[];
}

export const Result = ({ hakkaDefinitionCells }: ResultProps) => {
  const characterStyling = css`
    display: flex;
    align-items: baseline;
  `;

  return (
    <>
      {hakkaDefinitionCells.length !== 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Character</TableCell>
                <TableCell>Pronunciation</TableCell>
                <TableCell>Supplementary info</TableCell>
                <TableCell>Audio source</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {hakkaDefinitionCells.map(
                (
                  {
                    character,
                    pronunciation,
                    supplementaryInfo,
                    audioSource,
                    simplifiedCharacter,
                  },
                  index
                ) => {
                  return (
                    <TableRow
                      key={`definition-${index}-${character}-${pronunciation}`}
                    >
                      <TableCell>
                        <div css={characterStyling}>
                          <Typography variant="h5" component="h2">
                            {character}
                          </Typography>
                          {simplifiedCharacter && `(${simplifiedCharacter})`}
                        </div>
                      </TableCell>
                      <TableCell>{pronunciation}</TableCell>
                      <TableCell>{supplementaryInfo}</TableCell>
                      <TableCell>
                        <audio controls>
                          <source src={audioSource} />
                        </audio>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
