import axios from "axios";
import mock from "./mock.json";

interface SearchDefinitionResponse {
  statusCode: number;
  hakkaDefinitions: HakkaDefinitions;
}

export interface HakkaDefinitions {
  [key: string]: HakkaDefinition[];
}

export interface HakkaDefinition {
  pronunciation: string;
  mp3Name: string;
  supplementaryInfo: string;
  audioSource: string;
  simplifiedCharacter?: string;
}

export const getHakkaDefinitions = async (
  searchTerm: string
): Promise<HakkaDefinitions> => {
  const url = "https://txehnhq1ik.execute-api.eu-west-1.amazonaws.com/PROD";
  const response = await axios.post<SearchDefinitionResponse>(url, {
    searchTerm,
  });
  return response.data.hakkaDefinitions;

  // return Promise.resolve(mock.data.hakkaDefinitions);
};
