/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { useState } from "react";
import "./App.css";
import { HakkaDefinitionCell, InputSearch } from "./InputSearch";
import { Result } from "./Results";

function App() {
  const [hakkaDefinitionCells, setHakkaDefinitionCells] = useState<
    HakkaDefinitionCell[]
  >([]);

  const headingStyle = css`
    text-align: center;
  `;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={10}>
        <div css={headingStyle}>
          <Typography variant="h3" component="h1">
            客家話發音字典
          </Typography>
          <Typography variant="h5" component="h1">
            Hakka Pronunciation Dictionary
          </Typography>
        </div>
      </Grid>
      <Grid xs={6}>
        <InputSearch setHakkaDefinitionCells={setHakkaDefinitionCells} />
      </Grid>
      <Grid xs={10}>
        <Result hakkaDefinitionCells={hakkaDefinitionCells} />
      </Grid>
    </Grid>
  );
}

export default App;
