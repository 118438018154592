/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getHakkaDefinitions,
  HakkaDefinition,
  HakkaDefinitions,
} from "./service";

interface FormInput {
  searchTerm: string;
}

export interface HakkaDefinitionCell extends HakkaDefinition {
  character: string;
}

interface InputSearchProps {
  setHakkaDefinitionCells: (
    hakkaDefinitionCells: HakkaDefinitionCell[]
  ) => void;
}

export const InputSearch = ({ setHakkaDefinitionCells }: InputSearchProps) => {
  const { handleSubmit, control } = useForm<FormInput>();
  const [loading, setLoading] = useState(false);

  const getHakkaDefinitionCells = (
    hakkaDefinitions: HakkaDefinitions
  ): HakkaDefinitionCell[] => {
    return Object.entries(hakkaDefinitions).flatMap(
      ([character, definitions]) => {
        return definitions.map((definition) => {
          return {
            ...definition,
            character: character,
          };
        });
      }
    );
  };

  const onSubmit = async (formInput: FormInput) => {
    try {
      setLoading(true);
      const hakkaDefinitions = await getHakkaDefinitions(formInput.searchTerm);
      setHakkaDefinitionCells(getHakkaDefinitionCells(hakkaDefinitions));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const inputContainerStyle = css`
    display: flex;
    justify-content: center;
    gap: 8px;
  `;

  const textFieldStyle = css`
    flex-grow: 1;
  `;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={inputContainerStyle}>
        <Controller
          control={control}
          name="searchTerm"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              css={textFieldStyle}
              label="Search any word"
              variant="outlined"
            />
          )}
        />
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={loading}
        >
          Search
        </LoadingButton>
      </div>
    </form>
  );
};
